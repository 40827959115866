<template>
  <mdb-container class="my-5">
    <mdb-card class="card-image"
              style="background-image: url(https://mdbcdn.b-cdn.net/img/Photos/Others/gradient1.jpg)">
      <div class="text-white text-center py-5 px-4 my-5">
        <div>
          <h2 class="card-title h1-responsive pt-3 mb-5 font-bold">
            <strong>환영합니다. {{ $store.state.user_email }}님</strong></h2>
          <p class="mx-5 mb-5">
            오늘도 좋은 하루되세요.
          </p>
          <mdb-btn outline="white" size="md" icon="clone" @click="logout">로그아웃.</mdb-btn>
        </div>
      </div>
    </mdb-card>
  </mdb-container>
</template>

<script>
import {mdbCard, mdbBtn, mdbContainer} from 'mdbvue';
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: 'Welcome',
  components: {
    mdbCard,
    mdbBtn, mdbContainer
  },
  methods: {
    logout() {
      const self = this;
      firebase.auth().signOut().then(() => {
        self.$store.commit('setLogout');

      }).catch((error) => {
        console.log(error);
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>